<template>
	<div class="row justify-content-center">
		<div class="col-6 col-lg-2 text-center text-lg-left mb-3" v-for="(producto, index) in productosFiltered" :key="index">
			<div class="text-primary d-flex flex-column h-100 border border-primary py-3 px-2 rounded">
				<div class="mb-3 text-center d-flex align-items-center justify-content-center h-80" v-if="producto.image">
					<img v-lazy="producto.image" alt="Vehículo" width="102" height="102" class="object-fit-contain" v-if="producto.image">
				</div>
				<p class="font-weight-bold mb-3 text-center">{{producto.titulo}}</p>
				<small class="mt-auto px-2">{{producto.descripcion}}</small>
				<div class="mt-auto text-center" v-if="producto.route2">
					<div>
						<router-link :to="{name: producto.route2}" class="btn px-4 py-1 btn-inversion mb-1" v-if="producto.route2">
							Nuevo
						</router-link>
					</div>
					<div>
						<router-link :to="{name: producto.route}" class="btn px-4 py-1 btn-secondary" v-if="producto.route">
							Usado
						</router-link>
					</div>
				</div>
				<div class="mt-auto text-center" v-else>
					<router-link :to="{name: producto.route}" class="btn px-4 py-1 btn-secondary" v-if="producto.route">
						Solicítalo
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import AutoCompras from '@/assets/image/auto-compras-icono.webp'
	import Vehiculos from '@/assets/image/sobre-vehiculo.webp'

	export default {
		props: ['omit'],
		data(){
			return {
				productos: [
					{
						titulo: 'AutoCompras',
						descripcion: 'La mejor opción para comprar un carro',
						route: 'precalifique.auto-compras.index',
						route2: 'precalifique.auto-compras-nuevos.index',
						image: AutoCompras
					},
					{
						titulo: 'Sobre carro',
						descripcion: 'Préstamo con tu carro como garantía',
						route: 'precalifique.vehiculos.index',
						image: Vehiculos
					}
				]
			}
		},

		computed: {
			omitDF(){
				return this.omit || ''
			},
			productosFiltered(){
				if(this.omitDF == ''){
					return this.productos
				}
				
				return this.productos.filter(x => x.titulo != this.omitDF)
			}
		}
	}
</script>